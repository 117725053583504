const configs = {
    "localhost:3000": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:8b3e8d2bf88f16e111cb05"
    },
    "test.humoq.com": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:77b65a6f39b2707911cb05"
    },
    "humoq.com": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:ba6711f0fe398e6011cb05"
    },
    "humoq.de": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:05c9230010a96b4311cb05"
    },
    "humoq.it": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:78fba33080c721ba11cb05"
    },
    "humoq.nl": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:daff13ba77731f5011cb05"
    },
    "humoq.fr": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:fba07334a67da0ed11cb05"
    },
    "humoq.pl": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:8ecdfc4e40d7c42611cb05"
    },
    "humoq.cz": {
        apiKey: "AIzaSyCUkpIh2BCTA-KenMkPeIV-_rzuf5t8GGw",
        authDomain: "azberry.firebaseapp.com",
        projectId: "azberry",
        storageBucket: "azberry.appspot.com",
        messagingSenderId: "282463557728",
        appId: "1:282463557728:web:281677092fcd162911cb05"
    }
};
module.exports={
    configs
}