import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import Layout from '../components/Layout'
import { IntlProvider } from 'react-intl';
import '../public/global.scss';
import { appWithTranslation } from 'next-i18next';
import { firebaseCloudMessaging } from '../webPush';
import { onMessage } from "firebase/messaging";

export function reportWebVitals(metric) {
    console.log(metric)
}

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    useEffect(() => {
        setToken();
        async function setToken() {
            try {
                await firebaseCloudMessaging.init();
            } catch (error) {
                console.log(error);
            }
        }
    }, []);
    const { locale, defaultLocale } = router;
    return (
        <IntlProvider locale={locale} defaultLocale={defaultLocale}>
            <Layout>
                <Component {...pageProps} />
            </Layout>
        </IntlProvider>

    )
}

export default appWithTranslation(MyApp)