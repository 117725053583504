import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import{ configs } from './public/firebase-configs';
const firebaseConfig = configs[location.host || "humoq.com"];
const firebaseCloudMessaging = {
    init: async function () {
        if(localStorage.getItem('permission') == "1"){
            initializeApp(firebaseConfig);
            const messaging = getMessaging();
            getToken(messaging, { vapidKey: 'BFNk1yVMnCZPuvz_5hvGGg-HGrGqXZZ2gl6ujabVAwjjnl0JFFCzkC-SJwXALYGKf9Pv7Igdn8Tsth7Doq-HEIM' }).then((currentToken) => {
                if (currentToken) {
                    localStorage.setItem('fcm_token', currentToken.toString());
                }
              }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
              });
        }
    },
};
export { firebaseCloudMessaging };